import React from "react";
import {useObserver} from "mobx-react";
import {Collapse} from "reactstrap";

export const LatestUpdates = (props: {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    content: React.ReactNode,
}) => useObserver(() => {
    return (
        <div>
            <div className={"font-weight-bold text-uppercase text-center mt-3 mb-3"}
                 style={{ cursor: "pointer" }}
                 onClick={() => props.setIsOpen(!props.isOpen)}>
                Latest Updates <span className={"text-primary"}>{props.isOpen ? "▲" : "▼"}</span>
            </div>
            <Collapse isOpen={props.isOpen}>
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                    <hr className={"hr-primary"}/>
                    {props.content}
                    <hr className={"hr-primary"}/>
                </div>
            </Collapse>
        </div>
    );
});
