import * as React from "react";
import './Loading.css';

export function Loading() {
    return <div className="loader">
        <div className="loader-spinner"/>
    </div>;
}
    
export const LoadingIf = function(props: {children: any, isLoading : boolean}) {
    if(props.isLoading)
        return <Loading/>;
    return <>
        {props.children}
    </>    
};

export const LoadingIfNull = function(props: {children: any}) {
    if(props.children == null)
        return <Loading/>;
    return <>
        {props.children}
    </>
};