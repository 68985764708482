import SelectSearch from "react-select-search";
import React from "react";
import {FormGroup, Input, Label} from "reactstrap";

export const OrderSelect = (props: {
    options: string[],
    value: string,
    setValue: (value: string) => void,
    desc: boolean,
    setDesc: (desc: boolean) => void
}) => {
    const items = props.options.map(o => ({ name: o.split(/(?=[A-Z])/).join(' '), value: o }));
    return (
        <FormGroup>
            <SelectSearch
                search
                placeholder={"Order By"}
                value={props.value}
                options={items}
                onChange={selected => props.setValue(selected as any as string)}
            />
            <div style={{ marginLeft: "1.25rem" }}>
                <Input type="checkbox" checked={props.desc} onChange={x => props.setDesc(x.target.checked)} />
                <span onClick={() => props.setDesc(!props.desc)}>Descending order</span>
            </div>
        </FormGroup>
    )
};
