import { observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { Routes } from "src/routes";
import {BlockEditorPageStore} from "src/stores/pages/BlockEditorPageStore";
import {BlockListPageStore} from "src/stores/pages/BlockListPageStore";
import {NewBlockPageStore} from "src/stores/pages/NewBlockPageStore";
import {BlockPageStore} from "src/stores/pages/BlockPageStore";
import {CpuListPageStore} from "src/stores/pages/CpuListPageStore";
import {NewCpuPageStore} from "src/stores/pages/NewCpuPageStore";
import {CpuEditorPageStore} from "src/stores/pages/CpuEditorPageStore";

export class RootStore {
    @observable routerStore = new RouterStore(this, Routes, new RouterState("not-found"));
    @observable newBlockPageStore = new NewBlockPageStore(this);
    @observable blockEditorPageStore = new BlockEditorPageStore(this);
    @observable blockPageStore = new BlockPageStore(this);
    @observable blockListStore = new BlockListPageStore(this);
    @observable cpuListStore = new CpuListPageStore(this);
    @observable newCpuPageStore = new NewCpuPageStore(this);
    @observable cpuEditorPageStore = new CpuEditorPageStore(this);
}
