import React, {FC} from "react";
import {useObserver} from "mobx-react";
import {Col, Row} from "reactstrap";
import {range, useWindowSize} from "src/utils";

// The magic numbers come from Bootstrap grid.
const resolveColumns = (width: number) => {
    if (width > 992) return 3; // lg
    if (width > 768) return 2; // md
    if (width > 576) return 1; // sm
    return 1;
};

export function GridView<TDto>(props: { items: TDto[], render: (dto: TDto) => React.ReactNode }) {
    return useObserver(() => {
        const [width, _] = useWindowSize();
        const columns = resolveColumns(width);
        const rowsFractional = props.items.length / columns;
        const rows = Math.ceil(rowsFractional);
        return (
            <Row>
                {range(columns).map(column => (
                    <Col key={column} xs={12} sm={12} md={6} lg={4}>
                        {range(rows).map(row => {
                            const i = column * rows + row;
                            if (i < props.items.length) {
                                const item = props.items[i];
                                return <div key={i} className={"tile"}>{props.render(item)}</div>;
                            } else {
                                return <div key={i} className={"tile"} />;
                            }
                        })}
                    </Col>
                ))}
            </Row>
        );
    });
}
