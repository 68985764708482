import {useObserver} from "mobx-react";
import React from "react";
import {Col, Input, Row} from "reactstrap";
import {FlashSizeEditorStore, InformationUnit} from "src/components/FlashSizeEditorStore";

export const FlashSizeEditor = (props: { store: FlashSizeEditorStore }) => useObserver(() => {
    const s = props.store;
    return (
        <div>
            <Row>
                <Col sm={8}>
                    <Input value={s.size} onChange={e => s.changeSize(e.target.value)} />
                </Col>
                <Col sm={4}>
                    <select className={"form-control"} value={s.unit}
                            disabled={!s.canChangeUnit}
                            onChange={e => s.changeUnit(e.currentTarget.value as InformationUnit)}>
                        {s.units.map(l => <option value={l} key={l}>{l}</option>)}
                    </select>
                </Col>
            </Row>
            <span className="text-muted">
                Hex: {s.hex}
            </span>
        </div>
    )
})
