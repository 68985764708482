import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Form,
    Row
} from "reactstrap";
import {useObserver} from "mobx-react";
import {LoadingIf} from "src/components/Loading";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {CpuEditor} from "src/components/CpuEditor";

export const CpuEditorPage: FC = () => {
    const s = useRootStore().rootStore.cpuEditorPageStore;
    return useObserver(() => <div>
        <LoadingIf isLoading={s.cpu == null}>
            <Card>
                <CardBody>
                    <CardTitle tag="h4">
                        <RouterLink
                            routeName={RouteNames.editCpu}
                            params={{"id": s.cpu?.id?.toString() || ""}}>
                            Mcu {s.cpu?.name}
                        </RouterLink>
                        &nbsp;<Button disabled={!s.canSave} color="primary" onClick={() => s.save()}>Save</Button>
                        &nbsp;<Button disabled={!s.canSave} color="danger" onClick={() => s.delete()}>Delete</Button>
                    </CardTitle>
                    <div>
                        <Row>
                            <Col xl={5} lg={6} sm={10}>
                                <Form onSubmit={() => false}>
                                    <fieldset disabled={s.isLoading}>
                                        <CpuEditor store={s.cpu!}/>
                                    </fieldset>
                                </Form>
                                <br/>
                                <Button disabled={!s.canSave} color="primary" onClick={() => s.save()}>Save</Button>
                            </Col>
                        </Row>
                        <br/>
                    </div>
                </CardBody>
            </Card>
        </LoadingIf>
    </div>);
}