import React from "react";
import {Route} from "mobx-state-router";
import {convertRoutes} from "src/routing/route";
import {BlockListPage} from "src/pages/blocks/BlockListPage";
import {NewBlockPage} from "src/pages/blocks/NewBlockPage";
import {BlockEditorPage} from "src/pages/blocks/BlockEditorPage";
import {BlockPage} from "src/pages/blocks/BlockPage";
import {CpuListPage} from "src/pages/cpus/CpuListPage";
import {NewCpuPage} from "src/pages/cpus/NewCpuPage";
import {CpuEditorPage} from "src/pages/cpus/CpuEditorPage";

export enum RouteNames {
    notFound = "not-found",
    home = "home",
    newBlock = "new-block",
    editBlock = "edit-block",
    block = "block",
    cpus = "cpus",
    newCpu = "new-cpu",
    editCpu = "edit-cpu",
}

export const ViewMap = {
    [RouteNames.notFound]: <div>404 - Not Found</div>,
    [RouteNames.home]: <BlockListPage/>,
    [RouteNames.newBlock]: <NewBlockPage/>,
    [RouteNames.editBlock]: <BlockEditorPage/>,
    [RouteNames.block]: <BlockPage/>,
    [RouteNames.cpus]: <CpuListPage />,
    [RouteNames.newCpu]: <NewCpuPage />,
    [RouteNames.editCpu]: <CpuEditorPage />,
};

export const Routes: Route[] = convertRoutes([
    {
        pattern: "/not-found",
        name: RouteNames.notFound,
    },
    {
        pattern: "/",
        name: RouteNames.home,
        onEnter: async root => {
            root.blockListStore.search = "";
            await root.blockListStore.load();
            await root.blockListStore.loadLatestUpdates();
        }
    },
    {
        pattern: "/blocks/new",
        name: RouteNames.newBlock,
        onEnter: root => root.newBlockPageStore.reset()
    },
    {
        pattern: "/blocks/:id/edit",
        name: RouteNames.editBlock,
        onEnter: (root, to) => root.blockEditorPageStore.load(parseInt(to.params["id"]))
    },
    {
        pattern: "/blocks/:id",
        name: RouteNames.block,
        onEnter: (root, to) => root.blockPageStore.load(parseInt(to.params["id"]))
    },
    {
        pattern: "/cpus",
        name: RouteNames.cpus,
        onEnter: (root, to) => {
            root.cpuListStore.search = "";
            root.cpuListStore.load();
        }
    },
    {
        pattern: "/cpus/new",
        name: RouteNames.newCpu,
        onEnter: root => root.newCpuPageStore.reset(),
    },
    {
        pattern: "/cpus/:id/edit",
        name: RouteNames.editCpu,
        onEnter: (root, to) => root.cpuEditorPageStore.load(parseInt(to.params["id"]))
    },
]);