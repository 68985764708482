import {computed, observable} from "mobx";
import {api} from "src/globals";
import {BlockExtraFileInfoDto, BlockExtraFileType, BlockInfoDto, OwnerInfoDto} from "src/api";
import {RootStore} from "src/stores/RootStore";

export class BlockPageStore {
    @observable block: BlockInfoDto | null = null;
    @observable owner: OwnerInfoDto | null = null;
    @observable carModels: string[] = [];
    @observable files: BlockExtraFileInfoDto[] = []

    constructor(root: RootStore) {}

    @computed get images() {
        return this.files.filter(x => x.type == BlockExtraFileType.Photo)
            .concat(this.files.filter(x => x.type == BlockExtraFileType.ConnectionPhoto));
    }

    async load(id: number): Promise<void> {
        this.block = await api.block.getInfoById(id);
        this.carModels = await api.block.getCarModels(id);
        this.files = await api.block.getFileInfos(id);
        this.owner = await api.block.getBlockOwner(id);
    }
}