import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {Button, Card, CardBody, CardTitle, Form} from "reactstrap";
import {BlockEditor} from "src/components/BlockEditor";
import {useObserver} from "mobx-react";
import {NewBlockPageStore} from "src/stores/pages/NewBlockPageStore";


export const NewBlockPage: FC = () => {
    const {
        rootStore: {newBlockPageStore: store},
    } = useRootStore();
    return useObserver(() => <div style={{ minWidth: 400, maxWidth: 600, width: '100%', margin: "0 auto"}}>
        <Card>
            <CardBody>
                <CardTitle tag="h4">New ECU</CardTitle>
                <div>
                    <Form onSubmit={() => false}>
                        <fieldset disabled={store.isLoading}>
                            <BlockEditor store={store.block} additionalBlock={(
                                <div className={"mt-4"}>
                                    <div className={"mb-2 font-weight-bold"}>Photo</div>
                                    {!store.uploadedBlob && (
                                        <Button color="primary"
                                                disabled={store.fileIsUploading}
                                                onClick={() => store.uploadNewFile()}>
                                            +
                                        </Button>
                                    )}
                                    <div>
                                        {store.uploadedBlob?.name}{' '}
                                        {!!store.uploadedBlob && (
                                            <Button color="danger"
                                                    outline={true}
                                                    size="sm"
                                                    onClick={() => store.removeFile()}>
                                                x
                                            </Button>
                                        )}
                                    </div>
                                </div> 
                            )} />
                            <br/>
                            <Button color="primary"
                                    style={{ width: "100%" }}
                                    disabled={!store.canCreate}
                                    onClick={() => {
                                        store.create();
                                        return false;
                                    }}
                            >Create</Button>
                        </fieldset>
                    </Form>
                </div>
            </CardBody>
        </Card>
    </div>);
}