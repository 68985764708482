import React, {FC} from "react";
import {useObserver} from "mobx-react";
import Pagination from "react-js-pagination";
import {ListStore} from "src/stores/pages/BlockListPageStore";

type StorePaginationProps = {
    store: ListStore<any>;
};

export const StorePagination: FC<StorePaginationProps> = ({ store }) =>
    useObserver(() =>
        Boolean(store.items.length) ? (
            <div>
                <Pagination
                    totalItemsCount={store.totalPages * store.pageSize}
                    itemsCountPerPage={store.pageSize}
                    onChange={(pageNumber) => store.setPage(pageNumber - 1)}
                    activePage={store.page + 1}
                    pageRangeDisplayed={3}
                    itemClass="page-item"
                    linkClass="page-link"
                />
            </div>
        ) : (
            <div />
        )
    );