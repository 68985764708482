import {action, computed, observable} from "mobx";

export const formatSize = (size: string) => {
    const unit = FlashSizeEditorStore.guessInformationUnit(size);
    return FlashSizeEditorStore.convertSize(size, unit) + " " + unit;
};

export enum InformationUnit {
    hex = "Hex",
    bytes = "B",
    kiloBytes = "KB",
    megaBytes = "MB",
}

export class FlashSizeEditorStore {
    @observable size: string = "";
    @observable unit: InformationUnit = InformationUnit.bytes;
    @observable units = [
        InformationUnit.bytes,
        InformationUnit.kiloBytes,
        InformationUnit.megaBytes,
        InformationUnit.hex
    ];

    constructor(flashSize: number) {
        const size = flashSize.toString();
        this.unit = FlashSizeEditorStore.guessInformationUnit(size);
        this.size = FlashSizeEditorStore.convertSize(size, this.unit);
    }

    @computed get value(): number {
        switch (this.unit) {
            case InformationUnit.hex:
                return parseInt(this.size, 16);
            case InformationUnit.bytes:
                return parseInt(this.size);
            case InformationUnit.kiloBytes:
                return parseInt(this.size) * 1024; 
            case InformationUnit.megaBytes:
                return parseInt(this.size) * 1024 * 1024;
        }
    }

    @computed get hex(): string {
        const integer = this.value;
        if (isNaN(integer))
            return '';
        return `0x` + integer.toString(16);
    }

    @computed get canChangeUnit() {
        const currentUnit = FlashSizeEditorStore.guessInformationUnit(this.size);
        return currentUnit !== InformationUnit.hex;
    }

    @action changeUnit(unit: InformationUnit) {
        const currentUnit = FlashSizeEditorStore.guessInformationUnit(this.size);
        if (currentUnit === InformationUnit.hex)
            unit = InformationUnit.hex;
        this.unit = unit;
    }

    @action changeSize(value: string) {
        if (!value)
            this.size = "0";
        if (!/^(0x[0-9a-fA-F]+|0x|00x[0-9a-fA-F]+|[0-9a-fA-F]+)$/.test(value))
            return;
        if (value.startsWith('00x'))
            value = value.substring(1);
        const unit = FlashSizeEditorStore.guessInformationUnit(value);
        if (unit == InformationUnit.hex)
            this.unit = unit;
        this.size = value;
    }

    static convertSize(size: string, unit: InformationUnit) {
        switch (unit) {
            case InformationUnit.hex:
            case InformationUnit.bytes:
                return size;
            case InformationUnit.kiloBytes:
                return (parseInt(size) / 1024).toString();
            case InformationUnit.megaBytes:
                return (parseInt(size) / 1024 / 1024).toString();
        }
    }

    static guessInformationUnit(size: string): InformationUnit {
        const hasHexPrefix = size.startsWith('0x');
        const containsHexLetters = /[a-fA-F]/g.test(size);
        const twoLastZeroDigits = size.length > 2 && size.slice(size.length - 2, size.length) == '00';
        if (hasHexPrefix || containsHexLetters || twoLastZeroDigits)
            return InformationUnit.hex;
        const bytes = parseInt(size);
        if (bytes % (1024 * 1024) == 0)
            return InformationUnit.megaBytes;
        if (bytes % 1024 == 0)
            return InformationUnit.kiloBytes;
        return InformationUnit.bytes;
    }
}