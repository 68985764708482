import {ChangeEvent, useContext, useLayoutEffect, useState} from "react";
import {MobXProviderContext} from "mobx-react";
import {RootStore} from "src/stores/RootStore";

export const createBlob = async (file: File) => {
    const response = await fetch('/api/blobs/create', {
        method: 'POST',
        body: file
    });
    const blobInfo = await response.json();
    return blobInfo.id;
}

export const useRootStore = () => useContext(MobXProviderContext) as { rootStore: RootStore };

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const range = (n: number) => n > 0 ? [...Array(n).keys()] : [];

export const toRussianDate = (raw?: string | null, time: boolean = true) => {
    if (!raw) return;
    const datetime = new Date(raw.split(".")[0]);
    return time ? datetime.toLocaleString("ru") : datetime.toLocaleString("ru").split(',')[0];
};

export function isNullOrWhitespace(s: string | undefined | null):  boolean {
    if (s) {
        if (s.length == 0)
            return true;
        return s.match(/^\s+$/) != null;
    }
    else return true;
}

export function emptyIfNull(s: string | undefined | null) : string
{
    if(s)
        return s;
    return "";
}

export function contains(where: string, what: string)
{
    return where.indexOf(what) != -1;
}

export function preventDefault(cb: () => void) : (ev: any) => boolean {
    return ev=> {
        ev.preventDefault();
        cb();
        return false;
    };
}

export function dmap<TValue, TRes>(d: {[key: string] : TValue}, cb: (key: string, value: TValue) => TRes) : TRes[] {
    const rv : TRes[] = [];
    for (const k in d)
    {
        if (d.hasOwnProperty(k))
            rv.push(cb(k, d[k]));
    }
    return rv;
}

export function propCount(d: {[key: string] : any}) : number
{
    let cnt = 0; 
    for (const k in d)
    {
        if(d.hasOwnProperty(k))
            cnt++;
    }
    return cnt;
}

export const nameof = <T>(name: keyof T) => name;
export const nameofFactory = <T>() => (name: keyof T) => name;

export function bind<T>(model: T, name: keyof T, type?: string) : {
    value: string,
    onChange: any
} {
    let rv = {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
            var value : any;
            if(e.currentTarget.type == "checkbox")
            {
                value = e.currentTarget.checked;
            }
            else if (e.currentTarget.type == "number"){
                if (isNullOrWhitespace(e.currentTarget.value)){
                    e.currentTarget.value = "0";
                }
                value = e.currentTarget.value;
            }
            else
                value = e.currentTarget.value;
            
            const skey = name as string;
            const setter = "set"+ skey.charAt(0).toUpperCase() + skey.substr(1);
            if(model[setter] != null)
            {
                model[setter](value);
            }
            else
                model[name as string] = value;
        },
        
    } as any;
    if(type == "checkbox")
        rv.checked = model[name as string];
    else
        rv.value = model[name as string];
    if(type != null)
        rv.type = type;
    return rv;
}

export const bindFactory = <T>(model: T) => (name: keyof T, type?: string) => bind<T>(model, name, type);

export function o<T>(someObject: T, defaultValue: T = {} as T) : T {
    if (typeof someObject === 'undefined' || someObject === null)
        return defaultValue;
    else
        return someObject;
}

export function copyStringToClipboard (str: string) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
        document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

export function groupBy<TItem>(xs: TItem[], keySelector: (item: TItem) => string) : {[key: string] : TItem[]} {
    const rv : {[key: string] : TItem[]} = {};
    for(const i of xs)
    {
        const key = keySelector(i);
        let arr = rv[key];
        if(arr == null)
            arr = rv[key] = [];
        arr.push(i);
    }
    return rv;
}

export function groupByNumber<TItem>(xs: TItem[], keySelector: (item: TItem) => number) : {[key: number] : TItem[]} {
    const rv : {[key: number] : TItem[]} = {};
    for(const i of xs)
    {
        const key = keySelector(i);
        let arr = rv[key];
        if(arr == null)
            arr = rv[key] = [];
        arr.push(i);
    }
    return rv;
}

function ensureElement(type: string, id: string, multiple = false) {
    let a = document.getElementById(id);
    if (a == null) {
        a = document.createElement(type);
        a.style.position = "fixed";
        a.style.left = "-1000px";
        multiple && a.setAttribute("multiple", "true");
        document.body.appendChild(a);
    }
    return a;
}

export function openFile(): Promise<File> {
    return new Promise<File>((resolve) => {
        let input = ensureElement("input", "utils-open-file-input") as HTMLInputElement;
        input.type = "file";
        input.onchange = function () {
            if (input.files && input.files.length > 0) {
                resolve(input.files[0]);
            }
        };
        input.click();
    });
}