import {computed, observable} from "mobx";
import {BlockCpu} from "src/api";
import {NamedEntityCrudStore} from "src/stores/components/NamedEntityListStore";
import {api} from "src/globals";
import {isNullOrWhitespace} from "src/utils";
import {FlashSizeEditorStore} from "src/components/FlashSizeEditorStore";

export class CpuEditorStore {
    @observable id : number | null;
    @observable familyId : NamedEntityCrudStore;
    @observable name : string;
    @observable flash : FlashSizeEditorStore;
    @observable createdAt : string;
    @observable updatedAt : string;

    constructor(cpu : BlockCpu | null) {
        if (cpu) {
            this.id = cpu.id;
            this.familyId = new NamedEntityCrudStore(api.blockCpuFamily, cpu.familyId);
            this.name = cpu.name;
            this.flash = new FlashSizeEditorStore(cpu.flashSize);
            this.createdAt = cpu.createdAt;
            this.updatedAt = cpu.updatedAt;
        } else {
            this.id = null;
            this.familyId = new NamedEntityCrudStore(api.blockCpuFamily, null);
            this.name = "";
            this.flash = new FlashSizeEditorStore(0);
            this.createdAt = "";
            this.updatedAt = "";
        }
    }
    
    @computed get value() : BlockCpu | null {
        if (isNullOrWhitespace(this.name))
            return null;
        const flashSize = this.flash.value;
        if (isNaN(flashSize))
            return null;
        return {
            id: this.id || 0,
            name: this.name,
            flashSize: flashSize,
            familyId: this.familyId.selectedId,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        };
    }
}