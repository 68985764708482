import {ListStore} from "src/stores/pages/BlockListPageStore";
import {BlockCpuOrder, BlockCpuSearchFilters, BlockCpuSearchResult, BlockSearchResult, SearchRange} from "src/api";
import {RootStore} from "src/stores/RootStore";
import {api} from "src/globals";
import {action, observable, reaction} from "mobx";

export class CpuListPageStore extends ListStore<BlockCpuSearchResult> implements BlockCpuSearchFilters {
    @observable name: string = '';
    @observable family: string = '';
    @observable flash: SearchRange = { from: null, to: null };
    @observable filters: boolean = false;
    @observable orderOptions: BlockCpuOrder[] = Object.keys(BlockCpuOrder).map(x => x as BlockCpuOrder);
    @observable orderByField: BlockCpuOrder = BlockCpuOrder.Update;
    @observable orderByDescending: boolean = true;

    @observable latestUpdates: BlockCpuSearchResult[] = [];
    @observable latestUpdatesOpen: boolean = false;

    constructor(private readonly root: RootStore) {
        super((query, skip, take) => api.blockCpu.search({
            name: this.name,
            family: this.family,
            flash: this.flash,
            orderByField: this.orderByField,
            orderByDescending: this.orderByDescending,
        }, query, skip, take));
        reaction(() => [
            this.name,
            this.family,
            this.flash.from,
            this.flash.to,
            this.orderByField,
            this.orderByDescending,
        ], () => {
            this.load();
        }, {
            fireImmediately: true,
            delay: 100
        }); 
    }

    @action async loadLatestUpdates() {
        const latest = await api.blockCpu.getLatestUpdates(9);
        this.latestUpdates = latest.items;
    }

    @action toggleFilters() {
        if (this.filters) {
            this.filters = false;
            this.name = '';
            this.family = '';
            this.flash = { from: null, to: null };
            this.orderByDescending = true;
            this.orderByField = BlockCpuOrder.Update;
        } else {
            this.filters = true;
        }
    }
}