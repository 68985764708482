import {computed, observable, runInAction} from "mobx";

export class RequestTracking {

    @observable private __loadingCounter: number = 0;


    @computed get isLoading(): boolean {
        return this.__loadingCounter > 0;
    }

    private __getAllPromise: Promise<void> | null = null;
    private __getAllPromiseResolve: null | (() => void) = null;

    protected getAllFinishedPromise(): Promise<void> {
        if (this.__loadingCounter == 0)
            return Promise.resolve();

        if (this.__getAllPromise == null)
            this.__getAllPromise = new Promise(resolve => this.__getAllPromiseResolve = resolve);
        return this.__getAllPromise;
    }

    public async track<T>(cb: () => Promise<T>): Promise<T> {
        runInAction(() => this.__loadingCounter++);
        try {
            return await cb();
        } finally {
            runInAction(() => {
                this.__loadingCounter--;
                if (this.__loadingCounter == 0 && this.__getAllPromise) {
                    this.__getAllPromise = null;
                    if (this.__getAllPromiseResolve)
                        this.__getAllPromiseResolve();
                }
            });
        }
    }
}