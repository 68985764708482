import * as React from 'react';
import {RouterLink} from "mobx-state-router";
import "src/components/NavRouterLink.css";
import {RouterLinkProps} from "mobx-state-router/dist/types/components/router-link";

export const RouterNavLink = (props: RouterLinkProps & {children: any}) => {
    const {className, activeClassName, ...pass} = props;
    return <RouterLink className="nav-link" activeClassName="nav-link active" {...pass}/>
}

export const RouterNavLinkItem = (props: RouterLinkProps & {children: any}) => {
    return <li className="nav-item"><RouterNavLink {...props}/></li>
}