import {computed, observable} from "mobx";
import {RootStore} from "src/stores/RootStore";
import {RequestTracking} from "src/utils/RequestTracking";
import {api} from "src/globals";
import {RouteNames} from "src/routes";
import {CpuEditorStore} from "src/stores/components/CpuEditorStore";

export class NewCpuPageStore extends RequestTracking {
    @observable cpu = new CpuEditorStore(null);

    constructor(public rootStore: RootStore) {
        super();
        this.reset();
    }

    @computed get canCreate() {
        return !this.isLoading && this.cpu.value != null;
    }

    reset(): void {
        this.cpu = new CpuEditorStore(null);
    }

    async create() {
        const v = this.cpu.value;
        if (v == null)
            return;
        const id = await this.track(() => api.blockCpu.createCpu(v.name, v.flashSize, v.familyId));
        this.rootStore.routerStore.goTo(RouteNames.editCpu, {"id": id.toString()});
    }
}