import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {Button, Card, CardBody, CardTitle, Form} from "reactstrap";
import {useObserver} from "mobx-react";
import {CpuEditor} from "src/components/CpuEditor";

export const NewCpuPage: FC = () => {
    const {
        rootStore: {newCpuPageStore},
    } = useRootStore();
    return useObserver(() => <div style={{ minWidth: 400, maxWidth: 600, width: '100%', margin: "0 auto"}}>
        <Card>
            <CardBody>
                <CardTitle tag="h4">New MCU</CardTitle>
                <div>
                    <Form onSubmit={() => false}>
                        <fieldset disabled={newCpuPageStore.isLoading}>
                            <CpuEditor store={newCpuPageStore.cpu}/>
                            <br/>
                            <Button color="primary"
                                    disabled={!newCpuPageStore.canCreate}
                                    onClick={() => {
                                        newCpuPageStore.create();
                                        return false;
                                    }}
                            >Create</Button>
                        </fieldset>
                    </Form>
                </div>
            </CardBody>
        </Card>
    </div>);
}