import {computed, observable} from "mobx";
import {RootStore} from "src/stores/RootStore";
import {RequestTracking} from "src/utils/RequestTracking";
import {api} from "src/globals";
import {RouteNames} from "src/routes";
import {CpuEditorStore} from "src/stores/components/CpuEditorStore";

export class CpuEditorPageStore extends RequestTracking {
    @observable cpu: CpuEditorStore | null = null;

    constructor(public rootStore: RootStore) {
        super();
    }

    @computed get canSave() {
        return !this.isLoading && this.cpu && this.cpu.value != null;
    }

    async load(id: number): Promise<void> {
        this.cpu = null;
        this.cpu = new CpuEditorStore(await api.blockCpu.getById(id));
    }

    async save() {
        if (this.cpu == null || this.cpu.value == null || !this.canSave)
            return;
        const cpu = this.cpu.value;
        await api.blockCpu.update(cpu.id, cpu.name, cpu.flashSize, cpu.familyId);
        await this.load(cpu.id);
    }
    
    async delete() {
        if (this.cpu == null)
            return;
        if (!confirm("Delete?"))
            return;
        await this.track(() => api.blockCpu.delete(this.cpu!.id!));
        this.rootStore.routerStore.goTo(RouteNames.cpus);
    }
}