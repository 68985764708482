import React, {Component} from 'react'
export interface FileDropProps {
    handleDrop: (files: FileList) => void;
}

export class FileDrop extends Component<FileDropProps, {drag: boolean}> {
    private dropRef: React.RefObject<HTMLDivElement>;
    private dragCounter = 0;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            drag: false
        }
        this.dropRef = React.createRef();

    }

    handleDrag = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };
    handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true});
        }
    }
    handleDragOut = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({drag: false});
        }
    }
    handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({drag: false});
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }

    componentDidMount() {
        let div = this.dropRef.current!;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        let div = this.dropRef.current!;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    render() {
        return (
            <div
                style={{position: 'relative'}}
                ref={this.dropRef}
            >
                {this.state.drag &&
                <div
                    style={{
                        border: 'dashed grey 4px',
                        backgroundColor: 'rgba(255,255,255,.8)',
                        position: 'absolute',
                        top: -4,
                        bottom: -4,
                        left: -4,
                        right: -4,
                        zIndex: 9999
                    }}
                >

                </div>
                }
                {this.props.children}
            </div>
        );
    }
}
