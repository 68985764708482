import {useObserver} from "mobx-react";
import React, {FC} from "react";
import {Label, Input} from "reactstrap";
import {bind} from "src/utils";
import {NamedEntityCrudEditor} from "src/components/NamedEntityEditor";
import {FlashSizeEditor} from "src/components/FlashSizeEditor";
import {CpuEditorStore} from "src/stores/components/CpuEditorStore";

export const CpuEditor = (props: { store: CpuEditorStore }) => {
    const s = props.store;
    return useObserver(() => {
        return <div>
            <Label>Name</Label>
            <Input {...bind(s, "name")} placeholder={"Name"}/>
            <Label>Flash Size</Label>
            <FlashSizeEditor store={s.flash} />
            <Label>Mcu Family</Label>
            <NamedEntityCrudEditor store={s.familyId} placeholder={"Mcu Family"} />
        </div>;
    });
}