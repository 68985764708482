import {computed, observable} from "mobx";
import {BlockEditorStore} from "src/stores/components/BlockEditorStore";
import {RootStore} from "src/stores/RootStore";
import {RequestTracking} from "src/utils/RequestTracking";
import {api} from "src/globals";
import {RouteNames} from "src/routes";
import {createBlob, openFile} from "src/utils";

type BlobInfo = {
    blobId: any,
    name: string,
}

export class NewBlockPageStore extends RequestTracking {
    @observable block = new BlockEditorStore(null, []);
    @observable uploadedBlob: BlobInfo | null = null;
    @observable fileIsUploading = false;
    @observable blockIsCreating = false;

    constructor(public rootStore: RootStore) {
        super();
        this.reset();
    }

    @computed get canCreate() {
        return !this.isLoading && this.block.value != null && !this.blockIsCreating && this.block.isFidConfigured;
    }

    async removeFile() {
        this.uploadedBlob = null;
        this.fileIsUploading = false;
    }

    async uploadNewFile() {
        const file = await openFile();
        this.fileIsUploading = true;
        try {
            const blobId = await createBlob(file);
            const blobName = file.name;
            this.uploadedBlob = {
                blobId: blobId,
                name: blobName,
            };
        } finally {
            this.fileIsUploading = false;
        }
    }

    reset(): void {
        this.block = new BlockEditorStore(null, []);
        this.uploadedBlob = null;
        this.fileIsUploading = false;
        this.blockIsCreating = false;
    }

    async create() {
        const v = this.block.value;
        if (v == null)
            return;
        if (this.blockIsCreating)
            return;
        this.blockIsCreating = true;
        const b = v.block;
        try {
            const id = await this.track(() => api.block.createBlock(b.blockModel,
                b.carBrandId, b.manufacturerId, b.cpuId, b.additionalFlashSize,
                v.fids, b.storageBoxIdentifier, b.description));
            if (this.uploadedBlob) {
                const blobId = this.uploadedBlob.blobId;
                const blobName = this.uploadedBlob.name;
                await api.block.attachFile(id, blobId, blobName);
            }
            await this.rootStore.routerStore.goTo(RouteNames.block, {"id": id.toString()});
        } finally {
            this.blockIsCreating = false;
        }
    }
}